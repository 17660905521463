import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BOL',
    decimal: 18,
    lpAddresses: {
      321: '0xd37a481beedecb50a2d7426cd83acf95d07d17ad' // BOL-USDT LP
    },
    tokenSymbol: 'BOL',
    tokenAddresses: {
      321: '0x258e78df1f9235025294b29feabdffa18fb5138e'
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'BOL-KCS LP',
    decimal: 18,
    lpAddresses: {
      321: '0x1e2b787b957cca9f688e1f022ec719b2de8f7cc7', // BOL-KCS LP
    },
    tokenSymbol: 'BOL',
    tokenAddresses: {
      321: '0x258e78df1f9235025294b29feabdffa18fb5138e',
    },
    quoteTokenSymbol: QuoteToken.KCS,
    quoteTokenAdresses: contracts.wkcs,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'BOL-USDT LP',
    decimal: 18,
    lpAddresses: {
      321: '0xd37a481beedecb50a2d7426cd83acf95d07d17ad', // BOL-USDT LP
    },
    tokenSymbol: 'BOL',
    tokenAddresses: {
      321: '0x258e78df1f9235025294b29feabdffa18fb5138e',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'SHIB-BOL LP',
    decimal: 18,
    lpAddresses: {
      321: '0x89217c9910e0914651e030c98f9f5911d997a32f', // SHIB-BOL LP
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      321: '0x73b6086955c820370a18002f60e9b51fb67d7e1a',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'SHIB-USDT LP',
    decimal: 18,
    lpAddresses: {
      321: '0x5576cbf01cc075c0c2449dfde6b789eca332f12e', // SHIB-USDT LP
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      321: '0x73b6086955c820370a18002f60e9b51fb67d7e1a',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'KUDO-KCS LP',
    decimal: 18,
    lpAddresses: {
      321: '0xf5129cabd171d7d1521b9b3603c388da5a73ec65', // KUDO-KCS LP
    },
    tokenSymbol: 'KUDO',
    tokenAddresses: {
      321: '0x6665d66afa48f527d86623723342cfa258cb8666',
    },
    quoteTokenSymbol: QuoteToken.KCS,
    quoteTokenAdresses: contracts.wkcs,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'KUDO-BOL LP',
    decimal: 18,
    lpAddresses: {
      321: '0x2f4db60267587beaee67865fd92ee26f2dfe5628', // KUDO-BOL LP
    },
    tokenSymbol: 'KUDO',
    tokenAddresses: {
      321: '0x6665d66afa48f527d86623723342cfa258cb8666',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 7,
    risk: 2,
    lpSymbol: 'USDT/KCS LP',
    decimal: 18,
    lpAddresses: {
      321: '0x2dc9dc8c1df3458d07d6c80539d13516cf7ce63c', // USDT/KCS LP
    },
    tokenSymbol: 'WKCS',
    tokenAddresses: {
      321: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 8,
    risk: 2,
    lpSymbol: 'USDC/USDT LP',
    decimal: 18,
    lpAddresses: {
      321: '0xd9d9b35b45f13a333b636e43db9dcf3cce6d187c', // USDC/USDT LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      321: '0x980a5afef3d17ad98635f6c5aebcbaeded3c3430',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 10,
    risk: 2,
    lpSymbol: 'TUSD/USDT LP',
    decimal: 18,
    lpAddresses: {
      321: '0x587a96c9ba82cf207ecda04c59d72cf4d4238793', // TUSD/USDT LP
    },
    tokenSymbol: 'TUSD',
    tokenAddresses: {
      321: '0xd17027b85abf02721f953ee528721a980fa58941',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 9,
    risk: 5,
    lpSymbol: 'TUSD-BOL LP',
    decimal: 18,
    lpAddresses: {
      321: '0x5bafae8e422d10f24425ec7732bc3bc2dbccf69e', // TUSD-BOL LP
    },
    tokenSymbol: 'TUSD',
    tokenAddresses: {
      321: '0xd17027b85abf02721f953ee528721a980fa58941',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
]

export default farms
