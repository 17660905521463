export default {
  cake: {
    321: '0x258e78df1f9235025294b29feabdffa18fb5138e'
  },
  masterChef: {
    321: '0xD6A85cc3473876567d3b2503C1Ede299c28cd69e',
  },
  weth: {
    321: "0xf55af137a98607f7ed2efefa4cd2dfe70e4253b1"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    321: '0x6d900f6dfdf8985cd5cbb5298e006a500684f4b9'
  },
  shib: {
    321: '0x73b6086955c820370a18002f60e9b51fb67d7e1a'
  }, 
  usdc: {
    321: '0x980a5afef3d17ad98635f6c5aebcbaeded3c3430'
  },
  usdt: {
    321: '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48'
  },
  wkcs: {
    321: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521'
  },
  kcs: {
    321: '0x0000000000000000000000000000000000001010'
  }
}
