import { MenuEntry } from 'boltrswap-toolkit'
import { LinkStatus } from '@pancakeswap-libs/eslint-config-pancake'

const status = {
  LIVE: <LinkStatus>{
    text: "LIVE",
    color: "failure",
  },
  SOON: <LinkStatus>{
    text: "SOON",
    color: "#00aace",
  },
  NEW: <LinkStatus>{
    text: "NEW",
    color: "success",
  },
};

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://kccfarm.boltrswap.com',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://kccapp.boltrswap.com/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://kccapp.boltrswap.com/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
    status: status.LIVE
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
    status: status.LIVE
  },
  {
    label: 'NFT',
    icon: 'NftIcon',
    href: '#',
    status: status.SOON
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: '#',
    status: status.SOON
  },
  {
    label: 'Graph',
    icon: 'InfoIcon',
    href: '#',
    status: status.SOON
  },
  {
    label: 'Audit',
    icon: 'TeamBattleIcon',
    items: [
      {
        label: '0xGuard Audit',
        href: 'https://boltrswap.gitbook.io/boltr/boltr-documentations/security-audit',
		status: status.NEW
      },
      {
        label: 'Certik Audit',
        href: 'https://www.certik.org/#',
		status: status.SOON
      },
      {
        label: 'Hacken Audit',
        href: 'https://hacken.io/#',
		status: status.SOON
      },
    ],
  },
  {
    label: 'Listings',
    icon: 'GroupsIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/#',
		status: status.SOON
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/#',
		status: status.SOON
      },
      {
        label: 'Poocoin',
        href: 'https://poocoin.app/tokens/#',
		status: status.SOON
      },
      {
        label: 'Vfat',
        href: 'https://vfat.tools/kcc/#',
		status: status.SOON
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/boltrswap',
      },
      {
        label: 'Docs',
        href: 'https://boltrswap.gitbook.io/boltr/',
      },
      {
        label: 'Blog',
        href: 'https://boltr.medium.com/',
      },
    ],
  },
]

export default config
